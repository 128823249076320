
import './Info.css';
import { useEffect, useState } from 'react'
import logo from './assets/Lynx_Logo_Final.jpeg'
import background from './assets/Website_Background_opaque.png'
import background2 from './assets/background2.jpeg'
import background3 from './assets/background3.png'
import lynx1 from './assets/lynx1.png'
// import CrossfadeImage from 'react-crossfade-image';
import roadmap from './assets/roadmap.png'
import genesis from './assets/genesis.png'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { BsMegaphone, BsCamera, BsTrophy, BsChatDots } from 'react-icons/bs'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'
import lynx2 from "./assets/image2.png"
import WebFont from 'webfontloader';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



function App() {

  // const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Didact Gothic']
      }
    });

    // window.matchMedia("(max-width:600px)").addEventListener('change', (e)=>{setMobile(true)});

  }, []);  

  return (
    <div className="App" >
      <div style={{backgroundImage: `url('${background}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', overflowX:'scroll'}}>
      <BrowserView>
        <header className="App-header">
          <img src={logo} alt="logo" className="logo" />
          <div style={{display:'flex', flexDirection:'row', width: '35vw', justifyContent:'space-around'}}>
            <div className="header-options" ><HashLink smooth to={'/info#gameplay'} style={{ color: 'white', textDecoration:"none" }}>Gameplay</HashLink></div>
            <div className="header-options" ><HashLink smooth to={'/info#howitworks'} style={{ color: 'white', textDecoration:"none" }} >How it Works</HashLink></div>
            <div className="header-options" ><HashLink smooth to={'/info#roadmap'} style={{ color: 'white', textDecoration:"none" }} >Roadmap</HashLink></div>
          </div>
          <div style={{display:'flex', flexDirection:'column', marginBottom:'1vh'}}>
            <button className="social-button" onClick={(e) => {e.preventDefault(); window.location.href='https://discord.com/invite/kqUwRPGZmr';}}>Discord</button>
            <button className="social-button" onClick={(e) => {e.preventDefault(); window.location.href='https://twitter.com/LinksLynxNFT';}}>Twitter</button>
          </div>
        </header>
        <div style={{ height:'0.5vh', backgroundColor: '#c3c3c2', width: "100vw"}}></div>
        <div id='container-content' style={{display:'flex', flexDirection:"row", justifyContent: 'center', alignItems:'flex-start', width: "100vw", height:"65vh"}}>

          <div id="textbox" style={{display:'flex', flexDirection: 'column', marginTop:'8vh', justifyContent: "flex-start", alignItems: "flex-start", width:'20vw', color:'white'}}> 
            <div className='text' style={{fontSize:"3.5vh", marginBottom: "4vh", borderColor:'white', borderWidth:'0.07em', paddingLeft:'1.5vw',paddingRight:'1.5vw',paddingTop:'0.05vh',paddingBottom:'0.05vh', borderStyle:'solid', fontFamily:'Didact Gothic'}}>Links Lynx NFT | X</div>
            <div className='text' style={{fontSize:"2vh", textAlign:'start', marginBottom: "2vh",fontFamily:'Didact Gothic'}}>The first NFT based fantasy golf project with a social cause, where weekly and season long prizes will be paid out based on the Lynx you own and which PGA Tour players they represent!</div>
            <Link to="/" ><button style={{height:'5vh', width:'10vw', border:'solid', borderRadius:'1.5em', fontSize:'1.3em', background:'white', color:'gray'}}>Mint</button></Link>

          </div>
          <div style={{display:'flex', flexDirection: 'column', marginTop:'8vh', justifyContent: "flex-start", alignItems: "center", width:'20vw', color:'white', marginLeft:"5vw"}}> 
            <div className='text' style={{fontSize:"3.2vh", marginBottom: "3vh", borderColor:'white', borderWidth:'0.07em', padding:'0.4vw', borderStyle:'solid',fontFamily:'Didact Gothic'}}>Collection of 5,000 Unique 3D Links Lynx</div>
            <img src={lynx1} style={{height:'30vh'}}/>
            <div style={{display:'flex', flexDirection:'row', marginTop:'3.5vh', width:'100%', justifyContent:'space-evenly'}}>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <div style={{fontSize:'1vw'}}>Join our Discord!</div>
                <button style={{display:'flex', justifyContent:'center', alignItems: 'center', marginTop:'2vh',borderRadius:'100%', width:'3.5vw', height:'6.5vh', borderWidth:'3px', borderStyle:"solid", borderColor:'#06b86b', backgroundColor:'transparent'}} onClick={(e) => {e.preventDefault(); window.location.href='https://discord.com/invite/kqUwRPGZmr';}}><FaTwitter size='2vw' color='white'/></button>
              </div>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                <div style={{fontSize:'1vw'}}>Follow us on Twitter!</div>
                
                <button style={{display:'flex', justifyContent:'center', alignItems: 'center', marginTop:'2vh', borderRadius:'100%', width:'3.5vw', height:'6.5vh', borderWidth:'3px', borderStyle:"solid", borderColor:'#06b86b', backgroundColor:'transparent'}} onClick={(e) => {e.preventDefault(); window.location.href='https://twitter.com/LinksLynxNFT';}}><FaDiscord size='2vw' color='white'/></button>
              </div>
            </div>
          </div>
        </div>
        <div style={{display:'flex', flexDirection:'row'}}>
          <div id="gameplay" style={{marginTop:'10vh', backgroundImage: `url('${background2}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height:'70vh', width:'63vw'}}/>
          <div style={{marginTop:'10vh', height:'70vh', width:'37vw', backgroundColor:'#fff098', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <div style={{width:'25vw', textAlign:'left', lineHeight:'1.7'}}>
              <div style={{fontSize:'2.5vw', marginBottom:'2vh',fontFamily:'Didact Gothic'}}>Gameplay Overview</div>
              <div style={{fontSize:'1vw', fontWeight:'bold',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>By simply owning a (or multiple) Links Lynx NFT you are competing in a Fantasy Golf competition taking place on the Blockchain!</div>
              <div style={{fontSize:'1vw',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>Each Lynx represents a real PGA Tour pro which is indicated by the initials on your NFT. The prize money the PGA Tour pro earns equates to the points your Lynx earns.</div>
              <div style={{fontSize:'1vw',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>The holder with the most combined points from all of their Lynx wins the weekly prize AND whichever holder has accumulated the most points at the end of the season wins the season long prize (think FedEx Cup). </div>
            </div>
          </div>
        </div>

        <div id="howitworks" style={{display:'flex', flexDirection:'row'}}>
          <div style={{height:'100vh', width:'49vw', backgroundColor:'#fff098', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <div style={{width:'30vw', textAlign:'start', lineHeight: "1.65"}}>
              <div style={{marginBottom:'3vh', fontSize:'2vw',fontFamily:'Didact Gothic'}}>How it Works</div>
              <div style={{marginBottom:'1vh', fontSize:'1.2vw', fontWeight:'bold',fontFamily:'Didact Gothic'}}>The Rules are Simple:</div>
              <ul style={{fontSize:'1vw',fontFamily:'Didact Gothic'}}>
                <li>Each Links Lynx you own will have the initials of a PGA Tour pro on your NFT. The initials will tell you which PGA pro your Lynx represents.</li>
                <li>Each week as the PGA pros compete and win money, the earnings of the pro your Lynx represents will equal the number of points you earn</li>
                <li>In this example, we show someone who owns the Lynx with VH which represents Victor Hovland. Victor Hovland earned $540,000 at the Genesis Invitational which means this holder would have earned 540,000 points!</li>
                <li>BONUS: A rare trait some of the Lynx will have is called a "Multiplier". In this example this holders VH Lynx also has a 1.5X multiplier which simply multiplies the points by 1.5 resulting in this VH Lynx earning the holder 810,000 points instead of 540,000!</li>
                <img src={genesis} alt="genesis" style={{height:'20vh', marginTop:'2vh', marginBottom:'5vh'}}/>
                <li>The holders with the most points across all of the Lynx NFTs win for the week. AND the holders with the most points accumulated at the end of the season win the season long prizes!</li>
                <li>Prizes will be ETH (to be announced soon) AND we're looking to form partnerships to include other types of prizes such as tournament tickets, merch, etc. </li>
              </ul>
            </div>
          </div>
          <div style={{height:'100vh', width:'51vw', backgroundColor:'#f9f9f9', display:'flex', justifyContent:'center', alignItems:'center' }}>
          <img src={lynx2} style={{width:'35vw'}}/>
          </div>
        </div>

        <div style={{display:'flex', flexDirection:'row', height:'60vh', justifyContent:'center', alignItems:'center'}}>
          <div style={{width:'25vw', backgroundColor:'#ededed', height:'100%', display:'flex', flexDirection:'column', justifyContent:'baseline', alignItems:'center'}}>
            <div style={{width:'45%', marginTop:'5vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
              <BsCamera color="#717171" size='4vw'/>
              <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'1.5vw', marginTop:'3vh', color:'#4bd1a0'}}>Snapshot of Wallets</div>
              <ul style={{textAlign:'start', lineHeight:'1.5', fontSize:'1vw', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
                <li>Snapshot of wallets will be taken just before the last putt on 18 of the tournament</li>
                <li>We will not combine wallets, so its recommended you hold all your Lynx in the same wallet to accumulate points</li>
              </ul>
            </div>
          </div>
          <div style={{width:'25vw', height:'100%', display:'flex', flexDirection:'column', justifyContent:'baseline', alignItems:'center'}}>
            <div style={{width:'45%', marginTop:'5vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
              <BsMegaphone color="#717171" size='4vw'/>
              <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'1.5vw', marginTop:'3vh'}}>Buy, Sell, Trade</div>
              <ul style={{textAlign:'start', lineHeight:'1.5', fontSize:'1vw', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
                <li>You can buy and hold as many Lynx as you want</li>
                <li>All secondary buying and selling will occur on OpenSea</li>
                <li>The Links Lynx will be on the Ethereum Blockchain</li>
              </ul>
            </div>
          </div>
          <div style={{width:'25vw', backgroundColor:'#ededed', height:'100%', display:'flex', flexDirection:'column', justifyContent:'baseline', alignItems:'center'}}>
            <div style={{width:'45%', marginTop:'5vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <BsTrophy color="#717171" size='4vw'/>
              <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'1.5vw', marginTop:'3vh', color:'#4bd1a0'}}>Snapshot of Wallets</div>
              <ul style={{textAlign:'start', lineHeight:'1.5', fontSize:'1vw', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
                <li>The top 3 wallets with the most weekly points will be paid out in ETH (Prize amounts to be announced soon)</li>
                <li>The top 5 wallets with the most points accumulated across the whole season will be paid in ETH at the end of the Tour Championship!</li>
              </ul>
            </div>
          </div>
          <div style={{width:'25vw', height:'100%', display:'flex', flexDirection:'column', justifyContent:'baseline', alignItems:'center'}}>
            <div style={{width:'45%', marginTop:'5vh', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <BsChatDots color="#717171" size='4vw'/>
              <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'1.5vw', marginTop:'3vh'}}>Other/Airdrops</div>
              <ul style={{textAlign:'start', lineHeight:'1.5', fontSize:'1vw', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
                <li>Remember that ALL Lynx hold value because any week on Tour mid and lower tiered players are shooting up the leaderboard!</li>
                <li>We will be doing airdrops of Lynx representing new PGA Tour pros that weren't previously represented!</li>
              </ul>
            </div>
          </div>

         
        </div>
        <div id="roadmap" style={{height:'100vh', width:'100vw', backgroundColor:'#d4d4d2', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div style={{marginBottom:'1.5vh', fontSize:'2.5vw', fontWeight:'bold', letterSpacing:'1vw'}}>ROADMAP</div>
          <img src={roadmap} style={{height:'85vh'}}/>
        </div>
        <div style={{height:'9vh', backgroundColor:'#2f2e2e'}}>
          <div style={{color:'white', paddingTop:'1.5vh', fontSize:'1vw'}}>© 2023 by Links Lynx LLC</div>

        </div>

      </BrowserView>
      <MobileView>
        <div style={{height:'80px', backgroundColor:'#414141', display:'flex', flexDirection:'row', justifyContent:'space-around', alignItems:'center'}}>
          <img src={logo} alt="logo" className="logo" style={{height:'60%'}}/>
          <button onClick={(e) => {e.preventDefault(); window.location.href='https://discord.com/invite/kqUwRPGZmr';}}><FaDiscord size='40px' color='white'/></button>
          <button onClick={(e) => {e.preventDefault(); window.location.href='https://twitter.com/LinksLynxNFT';}}><FaTwitter size='40px' color='white'/></button>

        </div>
        <div style={{display:'flex', flexDirection:'column', color:'white', fontFamily:'Didact Gothic', justifyContent:'center',alignItems:'center', marginTop:'40px', width:'100vw' }}>
          <div style={{fontSize:'30px', border: '2px solid', width:'70vw'}}>Links Lynx NFT | X</div>
          <div style={{fontSize:'18px', width:'70vw'}}>The first NFT based fantasy golf project with a social cause, where weekly and season long prizes will be paid out based on the Lynx you own and which PGA Tour players they represent!</div>
          <Link to="/" ><button style={{height:'5vh', width:'200px', border:'solid', borderRadius:'1.5em', fontSize:'1.3em', background:'white', color:'gray'}}>Mint</button></Link>
          <div style={{fontSize:'25px', border: '2px solid', width:'70vw', marginTop:'20px'}}>Collection of 5,000 Unique 3D Links Lynx</div>
          <div style={{fontSize:'18px', width:'70vw'}}>The first NFT based fantasy golf project with a social cause, where weekly and season long prizes will be paid out based on the Lynx you own and which PGA Tour players they represent!</div>
          <img src={lynx1} style={{height:'30vh'}}/>
        </div>
        <div style={{marginTop:'30px', backgroundColor:'black', height:'80vh', width:'100vw', color:'white'}}>
          <div id="gameplay" style={{backgroundImage: `url('${background3}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height:'80vh', width:'100vw', color:'white', display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div style={{width:'90vw'}}>
            <div style={{fontSize:'30px', fontWeight:'bold', marginBottom:'2vh',fontFamily:'Didact Gothic'}}>Gameplay Overview</div>
            <div style={{fontSize:'18px', fontWeight:'bold',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>By simply owning a (or multiple) Links Lynx NFT you are competing in a Fantasy Golf competition taking place on the Blockchain!</div>
            <div style={{fontSize:'18px',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>Each Lynx represents a real PGA Tour pro which is indicated by the initials on your NFT. The prize money the PGA Tour pro earns equates to the points your Lynx earns.</div>
            <div style={{fontSize:'18px',marginBottom:'2vh',fontFamily:'Didact Gothic'}}>The holder with the most combined points from all of their Lynx wins the weekly prize AND whichever holder has accumulated the most points at the end of the season wins the season long prize (think FedEx Cup). </div>
            </div>
          </div>
        </div>
        <div style={{backgroundColor:'#fff098', width:'100vw', color:'black',display:'flex', alignItems:'center', justifyContent:'center' }}>
          <div style={{width:"80%", marginTop:'20px', marginBottom:'20px'}}>
          <div style={{marginBottom:'3vh', fontSize:'30px',fontFamily:'Didact Gothic'}}>How it Works</div>
          <div style={{marginBottom:'1vh', fontSize:'15px', fontWeight:'bold',fontFamily:'Didact Gothic'}}>The Rules are Simple:</div>
          <ul style={{fontSize:'15px',fontFamily:'Didact Gothic'}}>
            <li>Each Links Lynx you own will have the initials of a PGA Tour pro on your NFT. The initials will tell you which PGA pro your Lynx represents.</li>
            <li>Each week as the PGA pros compete and win money, the earnings of the pro your Lynx represents will equal the number of points you earn</li>
            <li>In this example, we show someone who owns the Lynx with VH which represents Victor Hovland. Victor Hovland earned $540,000 at the Genesis Invitational which means this holder would have earned 540,000 points!</li>
            <li>BONUS: A rare trait some of the Lynx will have is called a "Multiplier". In this example this holders VH Lynx also has a 1.5X multiplier which simply multiplies the points by 1.5 resulting in this VH Lynx earning the holder 810,000 points instead of 540,000!</li>
            <li>The holders with the most points across all of the Lynx NFTs win for the week. AND the holders with the most points accumulated at the end of the season win the season long prizes!</li>
            <li>Prizes will be ETH (to be announced soon) AND we're looking to form partnerships to include other types of prizes such as tournament tickets, merch, etc. </li>
          </ul>
          <img src={genesis} alt="genesis" style={{height:'20vh'}}/>
          </div>
          <div></div>
        </div>
        <div style={{width:'100vw', color:'white', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
          <div style={{width:'80vw', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <BsTrophy color="white" size='60px' style={{marginTop:'50px'}}/>
            <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'25px', marginTop:'3vh', color:'#4bd1a0'}}>Snapshot of Wallets</div>
            <ul style={{textAlign:'center', lineHeight:'1.5', fontSize:'18px', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
              <li>The top 3 wallets with the most weekly points will be paid out in ETH (Prize amounts to be announced soon)</li>
              <li>The top 5 wallets with the most points accumulated across the whole season will be paid in ETH at the end of the Tour Championship!</li>
            </ul>
            <BsMegaphone color="white" size='60px' style={{marginTop:'20px'}}/>
            <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'25px', marginTop:'3vh', color:'#4bd1a0'}}>Buy, Sell, Trade</div>
            <ul style={{textAlign:'center', lineHeight:'1.5', fontSize:'18px', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
              <li>You can buy and hold as many Lynx as you want</li>
              <li>All secondary buying and selling will occur on OpenSea</li>
              <li>The Links Lynx will be on the Ethereum Blockchain</li>
            </ul>
            <BsTrophy color="white" size='60px' style={{marginTop:'20px'}}/>
            <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'25px', marginTop:'3vh', color:'#4bd1a0'}}>Rewards</div>
            <ul style={{textAlign:'center', lineHeight:'1.5', fontSize:'18px', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
              <li>The top 3 wallets with the most weekly points will be paid out in ETH (Prize amounts to be announced soon)</li>
              <li>The top 5 wallets with the most points accumulated across the whole season will be paid in ETH at the end of the Tour Championship!</li>
            </ul>
            <BsChatDots color="white" size='60px' style={{marginTop:'20px'}}/>
            <div style={{textTransform:'uppercase', fontWeight:'bold', fontSize:'25px', marginTop:'3vh', color:'#4bd1a0'}}>Other/Airdrops</div>
            <ul style={{textAlign:'center', lineHeight:'1.5', fontSize:'18px', marginTop:'2vh',fontFamily:'Didact Gothic'}}>
              <li>You can buy and hold as many Lynx as you want</li>
              <li>Remember that ALL Lynx hold value because any week on Tour mid and lower tiered players are shooting up the leaderboard!</li>
              <li>We will be doing airdrops of Lynx representing new PGA Tour pros that weren't previously represented!</li>
            </ul>
          </div>
          <div style={{fontSize:'30px',marginTop:'30px', letterSpacing:'10px'}}>ROADMAP</div>
          <img src={roadmap} style={{width:'100%', marginTop:'10px'}}/>
          <div style={{color:'white', marginTop:'20px', fontSize:'20px', marginBottom:'20px'}}>© 2023 by Links Lynx LLC</div>
        </div>


      </MobileView>
      </div>
    </div>

  );
}

export default App;
