import { useEffect, useState } from "react";
import {
  VStack,
  useDisclosure,
  Button,
  Text,
  HStack,
  Select,
  Input,
  Box
} from "@chakra-ui/react";
import SelectWalletModal from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { networkParams } from "./networks";
import { connectors } from "./connectors";
import { toHex, truncateAddress } from "./utils";
import background from './assets/attempt2.png'
import background2 from './assets/Website_Background_opaque.png'
import logo from './assets/Lynx_Logo_Final.jpeg'
import './styles.css';
import WebFont from 'webfontloader';
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import Web3 from "web3";
import { ethers } from 'ethers'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import image1 from './assets/1.png'
import image2 from './assets/2.png'
import image3 from './assets/3.png'
import image4 from './assets/4.png'
import { Link } from 'react-router-dom'
import { MerkleTree } from 'merkletreejs' 
import keccak256 from 'keccak256'
// import fs 

import contractABI from "./linksLynxFallBallABImainnet.json"
import { getAccountPath } from "ethers/lib/utils";

// const contractAddress = "0x99cfADFbCF3cbF3c143262635aca0eb0C8fFeE3d"; test net
const contractAddress = "0xBAC2Ba188054A616c7e40a99C9F0b31E60E0eC91"

// const signer = provider.getSigner();

// const contract = new ethers.Contract(contractAddress, contractABI, signer)

const whitelistAddresses = [
  "0x3A21f4b983B38dB78Da66908fd55e4489E833Ab4",
  "0xB552D7A2F116737eEe3967C3De43007381A05F38",
  "0x4e14fF7527E71E7176A9DB722F9505732Cf94f76",
  "0xeeF70724A3e15F37342a6824aB26D32d3e15Ec63",
  "0x75d6c938552E4d808Ca4e9881308972905fBD2CB",
  "0x8223A2fB5aEd86845045FBD834F19a646933965b",
  "0xa544526596b786A2Cdb3683F76827FEaCC48871e",
  "0x17e5aeff026E3292dc9649Cf7EaDED68e50E2615",
  "0xF6D8E3Bf712296Fc5d2753515f08E09eE8828421",
  "0x1Ccb1fCabEDfDeAc48982e7D7daab53BB862fa31",
  "0x9684CDFe740d7a488564C77471E207718E1cE1Ce",
  "0x93a865064C6694888573831597f03835dc90B375",
  "0x18deA2fEE4fd010B0A000Af7607BCeec608cE3Ef",
  "0xE9Ba5160961546E9c30bC6bA8a30bECe64404e33",
  "0xfB6Cc2667246B8a167B76aE54067D8D33fb20248",
  "0x6A7065361176428F99086376045338B5E24443Dd",
  "0x2Dc8Fe71493b0b07C48e6D62F20B607c11dcc24e",
  "0x9355F9b797599205C8dEbaE56F08F07a64962Dbd",
  "0xfe5261962713680C7600433DcD27bE7f5A67C34a",
  "0x7423872f24E58d052e4491120f941287Ba84335b",
  "0x5d9fbd984B9CeC714a4B14c38Ea83bBC82d06d69",
  "0x81860cBa6C8bF78859D088a02ff8a63b8d185b59",
  "0x8f537643435CeD1cfc4a449C2EB51e9701dCc8E8",
  "0x5D2051ee3F4213A216eE7e5416ad92F57dE9f20e",
  "0xF7A6cd5f656fb57954eCf4A0Cd93741620711ea2",
  "0x49b27872F5F14Eaa9A4FA78867808275dDA14492",
  "0x2925aC962000F8930fAA968EF5Cd9bb3c78fBD6C",
  "0x12e12dFffe4A1069d972f6AC1c41928F7d54Ffd8",
  "0x744f8D6525692e1A597c95013d7CfFbA75f1DAEb",
  "0xb3f997cb4f4ee79456a83c7d571e5f120a9c6a2f",
  "0xeBfa299a743705F35d5D49a7920a50a87089d9bd",
  "0x5A7E67eef85905dea4D5e00d11fB935828Dd1054",
  "0x322856622dB75cdd6e6d1EEda8e96170656AA6CA",
  "0x0fd8d21f281b6ae7f301549f0927f96557793ecf",
  "0x9f18166d10c6dd602f5383bac4a1ce141d05efcb",
  "0xd06735e17048b27d9b75f114c35b8f0d845aec16",
  "0xa08b0a2e73906678159d6a37f11d52222bceef94",
  "0x80E67504B77501Ba328b9eDcD734bc8257AdA421",
  "0x6ECb387d32475166B10356D6a6f9a36c830e16af",
  "0x1aA5A9A9e5FeADB5cB761325F693035c32f8272B",
  "0x38e9bFB1223b5606261E796eab6A5Af3e0ec5E8E",
  "0xffA8ecc9809b58536e8dD0956BfFA78CF37fd640",
  "0x0833774AC6494037Efe844d63Ef7D650b8d88403",
  "0xCF4CD7D93B8882Ae814942374142B196CA020e2a",
  "0xB20fe46b57dFd72E3d6502423c73c3949f9348A8",
  "0x380A7a20b0b99361C64779E6d8F7Fa8f085A2B28",
  "0x0eEF2b88ba4bF9aD89de8142f8abE9C33959F07B",
  "0x0eEF2b88ba4bF9aD89de8142f8abE9C33959F07B",
  "0xf5270876137EB89a9c48b2c829273058254d6CD2",
  "0x4cE8a7fC2A35CE8f40894461f42253C6Be02C9Cf",
  "0x0150Eb3cB45480D60a71cc34FcEE920b11b6A6cC",
  "0xF67E75Ace25daD866A6BEDEF7af584d937669c18",
  "0x6425ec6eA843e66dd4A7570Dd5DC237146cd3897",
  "0xdFD9DBd6314a24a6caA4bE9Baeac2929C393163a",
  "0x2d71438b9EeC215043Ec548876177d8Fb3aa9515",
  "0x70FB94A4CEf2aAAd5339ecDc552Ffe9aA4DBE107", //emil
  "0x5D0a7709a007028030e58bCe710e3489269D096d", //michael
  "0xAfEa68253252831dF36E320F07c133ce75e01b9f", // adddition 2
  "0x709bC2a5632641CF7bb427a868163492Ff5839f2" // adddition 2
]





export default function Home() {

  const [provider, setProvider] = useState(undefined)
  const [contract, setContract] = useState(undefined)
  const [signer, setSigner] = useState(undefined)
  const [totalMinted, setTotalMinted] = useState(0)
  const [mintNumber, setMintNumber] = useState(0)
  const [costPerNFT, setCostPerNFT] = useState(0)
  const [ethBalance, setEthBalance] = useState(0)
  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMsg, setErrorMsg] = useState("Default")

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    account,
    activate,
    deactivate,
    active
  } = useWeb3React();

  useEffect(()=>{
    if(window.ethereum){
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      setProvider(provider)
      const signer = provider.getSigner();
      setSigner(signer)

      const contract = new ethers.Contract(contractAddress, contractABI, signer)
      setContract(contract)
    } else {
      console.log('ERROR NO METAMASK')
    }
  },[])

  useEffect(() => {
    getCount()
  }, [active])

  useEffect(() => {
    getBalance()
  }, [active])


  const getCount = async () => {
    if(active){
      const count = await contract.totalSupply()
      // console.log(parseInt(count))
      const cost = await contract.cost()
      // console.log('here', ethers.utils.formatEther(cost))
      const parsedCost = ethers.utils.formatEther(cost)
      // console.log('costperNFT', Number(parseFloat(parsedCost).toFixed(2)))
  
      // const balance = await provider.getBalance(account)
      // console.log('balance', balance)
  
      // setEthBalance(ethers.utils.formatEther(balance))
      setCostPerNFT(parsedCost)
      setTotalMinted(parseInt(count))
  
      const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
      const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  
      const rootHash = merkleTree.getRoot().toString('hex');
  
      console.log('RootHash:', rootHash);
    }
  }

  const getBalance = async() => {
    if(active){
      const balance = await provider.getBalance(account)
      // console.log('balance', balance)

      setEthBalance(parseFloat(ethers.utils.formatEther(balance)).toFixed(2))
    }
  }
  



  const refreshState = () => {
    window.localStorage.setItem("provider", undefined);
  };

  const disconnect = () => {
    refreshState();
    deactivate();
    setEthBalance(0)
    setMintNumber(0)
  };

  const mint = async () => {

    const connection = contract.connect(signer)
    const addr = connection.address

    const publicMintStatus = await contract.publicMint()
    const cost = await contract.cost()
    const parsedCost = ethers.utils.formatEther(cost)
    const totalCost = parsedCost * mintNumber
    console.log('publicMintStatus', publicMintStatus, 'totalCost', totalCost)

    if(publicMintStatus == true){
      const result = await contract.Mint(mintNumber, {
        value: ethers.utils.parseEther(totalCost.toString())
      })
  
      await result.wait()
      
    } else {

      const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
      const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

      const rootHash = merkleTree.getRoot().toString('hex');

      console.log('RootHash:', rootHash);

      console.log('Whitelist Merkle Tree\n', merkleTree.toString());

      console.log('account', account)

      const accHash = keccak256(account)
      const hexProof = merkleTree.getHexProof(accHash)

      console.log('hexproof', hexProof)

      if(merkleTree.verify(hexProof, accHash, rootHash) == true) {

        const result = await contract.WhitelistMint(hexProof, mintNumber, {
          value: ethers.utils.parseEther(totalCost.toString())
        })
    
        await result.wait()
  
        console.log('result', result)

      } else {
        setErrorStatus(true)
        setErrorMsg("Error: Wallet Address not On Whitelist")
        console.log('error: not on whitelist')
      }




    }


    



    // console.log('account', account)
    // const web3 = new Web3(window.ethereum);
    // console.log('account', account)
    // const accounts = await ethereum.request({ method: 'eth_accounts' });
    // console.log('here', accounts)


    // // const contractJson = fs.readFileSync('./linkslynxABI.json')
    // // const abi = JSON.parse(contractJson)
    // // const realABI = {hello:'hello'}
    // console.log(contractABI)
    // const nftContract = await new web3.eth.Contract(contractABI, contractAddress)
    // console.log('account', account)
    // const nonce = await web3.eth.getTransactionCount(account, 'latest')
    // console.log('nounce', nonce)

    // const tx = {
    //   'from': account,
    //   'to': contractAddress,
    //   'nonce': nonce,
    //   'gas': 500000,
    //   'maxPriorityFeePerGas': 2999999987,
    //   'data': nftContract.methods.Mint(1).encodeABI()
    // };

    // const accounts = await ethereum.request({ method: 'eth_accounts' });
    // console.log('here', accounts)


    // const signedTx = await web3.eth.accounts.signTransaction(tx, web3.eth.accounts[0]);
    // const transactionReceipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

    // console.log(`Transaction receipt: ${JSON.stringify(transactionReceipt)}`);

    // try {
    //   const transactionHash = await ethereum.request({
    //     method: 'eth_sendTransaction',
    //     params: [
    //       {
    //         'from': account,
    //         'to': contractAddress,
    //         'nonce': nonce.toString(),
    //         'data': nftContract.methods.Mint(1).encodeABI()
    //       },
    //     ],
    //   });
    //   // Handle the result
    //   console.log(transactionHash);
    // } catch (error) {
    //   console.error(error);
    // }
    
  }

  // useEffect(() => {
  //   const provider = window.localStorage.getItem("provider");
  //   if (provider) activate(connectors[provider]);
  // }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Didact Gothic']
      }
    });
  }, []);  

  return (
    <div className='background' style={{backgroundColor:'black', overflowX:'scroll', width:'100vw', backgroundAttachment:'scroll'}}>
      <div className='background' style={{backgroundImage: `url('${background}')`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundAttachment: 'fixed'}}>
        {/* {window.ethereum ? <div>Hi</div>:<div>bye</div>} */}
        <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', alignItems:'center'}}>
          <div>
            <img src={logo} alt="logo" style={{margin:'1.5vw', width:'10vw'}} />
          </div>
          <div style={{margin:'2vw', display:'flex', flexDirection:'row', alignItems:'center'}}>
            {!active ? (
              <Button style={{height:'3.5vh', width:'10vw', fontSize:'1vw'}} onClick={onOpen}>Connect Wallet</Button>
            ) : (
              <Button style={{height:'3.5vh', width:'10vw',fontSize:'1vw'}} onClick={disconnect}>Disconnect</Button>
            )}
            <Tooltip label={account} placement="right">
              <Text style={{color:'white', marginLeft:'1.5vw', fontSize:'1vw'}}>{`Account: ${truncateAddress(account)}`}</Text>
            </Tooltip>
          </div>
        
          <SelectWalletModal isOpen={isOpen} closeModal={onClose} />
        </div>
        <div style={{marginLeft:'3vw'}}>
          <button style={{display:'flex', justifyContent:'center', alignItems: 'center', marginTop:'2vh',borderRadius:'100%', width:'3.5vw', height:'4vh', backgroundColor:'transparent'}} onClick={(e) => {e.preventDefault(); window.location.href='https://twitter.com/LinksLynxNFT';}}><FaTwitter size='2vw' color='white'/></button>
          <button style={{display:'flex', justifyContent:'center', alignItems: 'center', marginTop:'2vh', borderRadius:'100%', width:'3.5vw', height:'4vh', backgroundColor:'transparent'}} onClick={(e) => {e.preventDefault(); window.location.href='https://discord.com/invite/kqUwRPGZmr';}}><FaDiscord size='2vw' color='white'/></button>

        </div>
        {/* <div><img src=></img></di          swipeable={true}
            showThumbs={false}
            animationHandler={'fade'}
            infiniteLoop={true}
            transitionTime={2000}
            showArrows={false}v> */}
        <div className='container'>
          <div className='carousel' style={{marginLeft:'3vw', width:'22vw'}}>
            <Carousel 
              autoPlay
              width="100%"
              infiniteLoop={true}
              showThumbs={false}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
            >
              <div>
                  <img style={{opacity:'85%'}} src={image1} />
              </div>
              <div>
                  <img style={{opacity:'85%'}} src={image2} />
              </div>
              <div>
                  <img style={{opacity:'85%'}} src={image3} />
              </div>
              <div>
                  <img style={{opacity:'85%'}} src={image4} />
              </div>
            </Carousel>
          </div>

          
          <div className="frost" style={{alignSelf:'center', marginTop:'5vh', display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'center'}}>
            <div style={{color:'white', fontSize:'4vh', alignSelf:'center', marginTop:'4vh', fontFamily:'Didact Gothic'}}>
              Links Lynx NFT Fall Ball
            </div>
            <div style={{height:'0.2vh', backgroundColor:'white', width:'100%', display:'flex', flexDirection:'column', alignItems:'center'}}>
              <div style={{display:'flex', color:'white', fontFamily:'Didact Gothic', fontStyle:'italic',marginTop:'1vh', fontSize:'2.3vh', height:'3vh'}}>
                Where Fantasy Golf Meets NFTs
              </div>
              </div>

              {!active ? (
                  <Button style={{width:'24.5vh', marginTop:'15vh', fontSize:'2vh', height:'4vh'}}>Wallet Not Connected</Button>
                ) : (
                <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                  <Input style={{width:'12.5vh', marginTop:'15vh', fontSize:'1.5vh', height:'4vh'}}  placeholder='Number to mint...' onChange={(e)=>{setMintNumber(e.target.value)}} value={mintNumber} />
                  <Button style={{width:'12.5vh', marginTop:'15vh', fontSize:'2vh', height:'4vh'}} onClick={mint}>Mint</Button>
                </div>  // style={{width:'10vw', marginTop:'15vh', fontSize:'2vh', height:'4vh'}}
                )
              }
              <div style={{marginTop:'2vh', fontSize:'2vh'}}>
                Total Cost: {mintNumber} * {costPerNFT} ETH = {(costPerNFT*mintNumber).toFixed(3)} ETH
              </div>
              <div style={{fontSize:'2vh'}}>
                Your ETH Balance: {ethBalance}
              </div>
              {errorStatus &&<div style={{fontSize:'1.5vh'}}>{errorMsg}</div>}
          </div>
        </div>
        
        <div style={{alignSelf:'center', justifySelf:'center', color:'white', marginTop:'15vh', fontSize:'1vw', marginBottom:'1vh', display:'flex', flexDirection:'row', textDecoration:'underline'}}>
          <Link to="/info" ><div>GAMEPLAY OVERVIEW</div></Link >
          <div style={{marginLeft:'1vw', marginRight:'1vw'}}>|</div>
          <div>HOW IT WORKS</div>
        </div>
        {/* <div style={{alignSelf:'center', justifySelf:'center', color:'white', marginTop:'11.5vh', fontSize:'1vw', marginBottom:'1vh'}}>GAMEPLAY OVERVIEW</div>
        <div style={{alignSelf:'center', justifySelf:'center', color:'white', marginTop:'11.5vh', fontSize:'1vw', marginBottom:'1vh'}}>HOW IT WORKS</div> */}
      </div>
    </div>
  );
}
